import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import ContactForm from "../pages/contact/ContactForm";
import Content, { HTMLContent } from "../components/Content";

import "./informations-pratiques.scss";

export const InformationsPratiquesTemplate = ({
  title,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <div className="InformationsPratiques">
      <div className="InformationsPratiques__header">
        <h1>{title}</h1>
      </div>
      <div className="InformationsPratiques__content">
        <div className="InformationsPratiques__userContent">
          <PageContent content={content} className="Markdown" />
        </div>
        <div className="InformationsPratiques__contactForm">
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

InformationsPratiquesTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const InformationsPratiques = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <section className="Layout__body">
        <InformationsPratiquesTemplate
          contentComponent={HTMLContent}
          title={post.frontmatter.title}
          content={post.html}
        />
      </section>
    </Layout>
  );
};

InformationsPratiques.propTypes = {
  data: PropTypes.object.isRequired,
};

export default InformationsPratiques;

export const InformationsPratiquesQuery = graphql`
  query InformationsPratiques($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
